





import { Component, mixins } from 'nuxt-property-decorator';

import { PageContentMixin } from '@/composables/page-content';

import { IconSearch, IconArrowRight } from '@/icons';
import { StartpageContentDataFragment } from '@/graphql/generated';

@Component({
  components: {
    IconSearch,
    IconArrowRight,
  },
})
export default class IndexPage extends mixins(PageContentMixin<StartpageContentDataFragment>()) {}
