import { defineComponent } from '@nuxtjs/composition-api';

import { usePageColor } from '@/components/animate/BodyBackground/BodyBackgroundState';

import { usePageContent } from '@/modules/cms';

export const PageContentMixin = <T = any>() => defineComponent({
  setup: () => ({
    ...usePageContent<T>(),
    ...usePageColor(),
  }),
});
